import { defineStore } from 'pinia';
import ErrorHelper from "@/helpers/ErrorHelper";
import { MQTTServerConfig } from "@/models/mqtt/MQTTServerConfig";
import ToastService from "@/services/ToastService";
import axios from "axios";

export class MQTTServerState {
  isLoaded = false;
  configs: MQTTServerConfig[] = [];

  updateInProgress = false;
  updateError = false;
}

export const useMqttServerStore = defineStore('mqttServer', {
  state: () => (new MQTTServerState()),
  getters: {},
  actions: {
    async load() {
      try {
        this.isLoaded = false;
        this.configs = [];
        const url = `rest/BitPool_V2/mqtt/server`;
        const response = await axios.get<MQTTServerConfig[]>(url);
        this.configs = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load MQTT config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.configs = [];
        this.isLoaded = true;
      }
    },
    async createUpdate(body: MQTTServerConfig) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/mqtt/server`;
        const response = await axios.post<MQTTServerConfig>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgress = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (body.Id) {
          const index = this.configs.findIndex((x) => x.Id === body.Id);
          if (index >= 0) {
            this.configs[index] = response.data;
          }
        } else {
          this.configs.push(response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save MQTT config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgress = false;
        this.updateError = true;
      }
    },
    async delete(id: string) {
      try {
        const url = `rest/BitPool_V2/mqtt/server/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        const index = this.configs.findIndex((x) => x.Id === id);
        if (index > -1) {
          this.configs.splice(index, 1);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete MQTT config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
  },
});